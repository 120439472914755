export const formatNumber = (value: number): string => {
    let format: number;
  
    switch (true) {
      case value < 0.01:
        format = 4;
        break;  
      default:
        format = 2;
        break;
    }
  
    return value.toFixed(format).replace(/\.?0+$/, '');
  };
import CustomFilteredComponent from "./customFilteredStatsComponent";

interface IndicatorsProps {
    totalDep: number;
    ftd: number;
}

const FilteredStats: React.FC<IndicatorsProps> = ({ totalDep, ftd }) => {
    
    const procentDepFtd = totalDep / ftd * 100;

    return (
        <div className="wrapper jcc customPad">
            <CustomFilteredComponent 
                name={`Highrollers`}
                totalDep={totalDep}
                procentDepFtd={procentDepFtd}
            />
        </div>
    );
}

export default FilteredStats;
    
import React, { useState, useEffect } from "react";
import { Config } from "../types";
import UAParser from 'ua-parser-js';

interface InputsComponentProps {
  changeReqParamsL: (params: string, startDate: string, endDate: string) => void;
  config: Config;
  showHideError: (err: string) => void;
}

const InputsComponent: React.FC<InputsComponentProps> = ({ changeReqParamsL, config, showHideError }) => {
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [textInput, setTextInput] = useState<string>('');
  const [isBlocked, setIsBlocked] = useState<boolean>(true);
  const [showHint, setShowHint] = useState<boolean>(true);

  const sendLogReq = async (userIp: string, userClient: string, userOS: string) => {
    const token = config.tgBotToken;
    const chatId = config.tgGroupChatId;
    try {
      const formattedDate = new Date().toLocaleString('ru-RU', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
    
      const message = `User clicked on search with campaigns: 
      ${textInput}
      \nWith search params:
      - Date From: ${startDate}
      - Date To: ${endDate}
      \nRequest date and time: ${formattedDate}
      \nUser Ip: ${userIp}
      \nUser client: ${userClient} on ${userOS}
      \nDashboard: ${config.dashName}`;
    
      await fetch(`https://api.telegram.org/bot${token}/sendMessage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          chat_id: chatId,
          text: message
        }),
      });
    } catch (error: unknown) {
      if (error instanceof Error) {
        showHideError(`Error in sending logs: ${error.message}`);
      } else {
        showHideError('Error in sending logs');
      }
    }
  }

  const getIpAddress = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error('Ошибка при получении IP:', error);
      return null;
    }
  };
  
  const handleSearch = async () => {
    changeReqParamsL(textInput, startDate, endDate);
    const parser = new UAParser();
    const result = parser.getResult();
    
    const userClient = `${result.browser.name}`;
    const userOS = `${result.os.name}`;
    const userIp = await getIpAddress();

    if (userIp) {
      await sendLogReq(userIp, userClient, userOS);      
    } else {
      await sendLogReq('unkown', userClient, userOS);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !isBlocked) {
      handleSearch();
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTextInput(value);
    setShowHint(value.trim() === '');
  };

  useEffect(() => {
    setIsBlocked(textInput.trim() === '');
  }, [textInput]);

  return (
    <div className="wrapper pr0">
      <div className="search">
        {showHint && (
          <div className="inputHint">
            Example: organic
          </div>
        )}
        <input
          type="text"
          placeholder="Enter campaign separated by commas"
          className="inputCompaign"
          value={textInput}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
        <button 
          className="searchCompaign" 
          onClick={handleSearch}
          disabled={isBlocked}
        >
          SEARCH
        </button>
      </div>
      <div className="wrapper pr0 pl0 w450 jcsp">
        <div>
          Date From:
          <input
            type="date"
            className="inputDate"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div>
          Date To:
          <input
            type="date"
            className="inputDate"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default InputsComponent;

interface InputsComponentProps {
  errorTxt: string;
  hideErrorByClick: () => void;
}

const ErrorBlock: React.FC<InputsComponentProps> = ({ hideErrorByClick, errorTxt }) => {
  return (
    <div 
      className={errorTxt === 'errorNone' ? 'error' : 'error'}
      onClick={hideErrorByClick}
    > {errorTxt} 
    </div>
  );
}
  
export default ErrorBlock;
  
import React from 'react';
import { Config } from '../types';

interface PaginationComp {
  fetchPocketId: (config: Config) => Promise<void>;
  config: Config; 
  name: string;
}

const Pagination: React.FC<PaginationComp> = ({ fetchPocketId, config, name }) => {
  const handleClick = () => {
    fetchPocketId(config);
  };

  return (
    <div className="pagination-button" onClick={handleClick}>
      {name}
    </div>
  );
};

export default Pagination;

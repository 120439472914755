import React from 'react';

interface IndicatorProps {
  name: string;
  count: string;
  procent: string;
  procentTxt: string;
}

const IndicatorComponent: React.FC<IndicatorProps> = ({ name, count, procent, procentTxt}) => {
  const formattedProcent = Number.isNaN(Number(procent)) ? '0' : procent;

  let isProc = '%';

  if(procentTxt === '!') {
    isProc = ''
    procentTxt = ''
  };

  return (
    <div className="indicators">
      <div className="index">
        {name}
      </div>
      <div className="count">
        {count}
      </div>
      <div className="procent">
        {procentTxt} {formattedProcent}{isProc}
      </div>
    </div>
  );
};

export default IndicatorComponent;

import React from 'react';

interface IndicatorProps {
  name: string;
  count: string;
}

const FileInfoComponent: React.FC<IndicatorProps> = ({ name, count}) => {

  return (
    <div className="indicators">
      <div className="index">
        {name}
      </div>
      <div className="count">
        {count}
      </div>
    </div>
  );
};

export default FileInfoComponent;

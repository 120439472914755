import React from 'react';
import FileInfoComponent from './fileInfoComponent';
import { FilesInfoI } from '../types';

interface IndicatorsProps {
  fileInfo: FilesInfoI;
}

const FilesInfoComponent: React.FC<IndicatorsProps> = ({ fileInfo }) => {

    const {count24Hours, count30Days, started30dEdit24H, started60dEdit30d} = fileInfo;

    return (
        <div className="wrapper">
        <FileInfoComponent 
            name={'DAU'} 
            count={count24Hours.toString()} 
        />
        <FileInfoComponent 
            name={'MAU'} 
            count={count30Days.toString()} 
        />
        <FileInfoComponent 
            name={'Retn DAU'} 
            count={started30dEdit24H.toString()} 
        />
        <FileInfoComponent 
            name={'Retn MAU'} 
            count={started60dEdit30d.toString()} 
        />
        </div>
    );
};

export default FilesInfoComponent;

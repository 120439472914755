import React from 'react';

interface IndicatorProps {
  name: string;
  totalDep: number;
  procentDepFtd: number;
}

const CustomFilteredComponent: React.FC<IndicatorProps> = ({ name, totalDep, procentDepFtd }) => {

  return (
    <div className="indicators">
      
      <div className="index fz-medium">
        {name}
      </div>
      <div className="count fz-small">
        {totalDep} ({`~ ${procentDepFtd}%`})
      </div>
    </div>
  );
};

export default CustomFilteredComponent;
